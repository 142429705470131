import { render, staticRenderFns } from "./StaffCard.vue?vue&type=template&id=69fa156d&scoped=true"
import script from "./StaffCard.vue?vue&type=script&lang=js"
export * from "./StaffCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fa156d",
  null
  
)

export default component.exports